import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ApplicationIds} from '../models/application';
import {APP_CONFIGS, AppActionItem, IAppConfig} from '../models/appmenu';
import {PlanningToolService} from './planning-tool.service';
import {UtilService} from './util.service';
import {environment} from "../../environments/local/environment";

@Injectable({
  providedIn: 'root'
})
export class ApplicationService {
  private hasApplicationAccess = true;

  constructor(
    private planningToolService: PlanningToolService,
    private utilService: UtilService
  ) {
  }

  setAppActionItemClickAction(appMenuItem: AppActionItem) {
    switch (appMenuItem.config.appId) {
      case ApplicationIds.CUSTOMER_PORTAL:
        appMenuItem.clickHandler = () => {
          this.utilService.goToLink(environment.links.applications.hcp);
        };
        break;
      case ApplicationIds.HPLUS_SERVICE:
        appMenuItem.clickHandler = () => {
          this.utilService.goToLink(environment.links.applications.mh2);
        };
        break;
      case ApplicationIds.LEVEL_2:
        appMenuItem.clickHandler = () => {
          this.utilService.goToLink(environment.redirect.host);
        };
        break;
      case ApplicationIds.ELECTRICITY_CALCULATION_TOOL:
        appMenuItem.clickHandler = () => {
          this.planningToolService.openTool(ApplicationIds.ELECTRICITY_CALCULATION_TOOL);
        };
        break;
      case ApplicationIds.TENDER_TOOL:
        appMenuItem.clickHandler = () => {
          this.planningToolService.openTool(ApplicationIds.TENDER_TOOL);
        };
        break;
      case ApplicationIds.HPLUS_DONGLE:
        appMenuItem.clickHandler = () => {
          this.utilService.goToLink(environment.links.applications.dongle);
        };
        break;
      case ApplicationIds.REMOTE_ADMIN:
        appMenuItem.clickHandler = () => {
          this.utilService.goToLink(environment.links.applications.remote_admin);
        };
        break;
      case ApplicationIds.REMOTE_DESKTOP:
        appMenuItem.clickHandler = () => {
          this.utilService.goToLink(environment.links.applications.remote_desktop);
        };
        break;
      case ApplicationIds.OMS_TOOL:
        appMenuItem.clickHandler = () => {
          this.utilService.goToLink(environment.links.applications.oms);
        };
        break;
    }
  }

  setAppAccess(hasApplicationAccess: boolean) {
    this.hasApplicationAccess = hasApplicationAccess;
  }

  hasAppAccess() {
    return this.hasApplicationAccess;
  }
}
