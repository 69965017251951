import {Pipe, PipeTransform} from '@angular/core';
import {APP_CONFIGS} from "../models/appmenu";

@Pipe({
  name: 'appTranslationKeys',
  standalone: true
})
export class AppTranslationKeysPipe implements PipeTransform {

  transform(id: string): {
    translationTitle: string,
    translationContent: string
  } | null {
    return APP_CONFIGS.find(i => i.appId === id);
  }

}
