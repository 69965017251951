<ng-container *ngIf="assignedApplications?.length>0; else noApps">
  <div *ngFor="let appSelection of assignedApplications" class="d-flex align-items-center mb-2">
    <div class="selection-logo">
      <ngx-l2-application-img [applicationId]="appSelection.id"></ngx-l2-application-img>
    </div>
    <div class="d-flex flex-column">
      <span class="bold mb-1">{{(appSelection.id |appTranslationKeys)?.translationTitle |translate}}</span>
      <span class="appDescription">{{(appSelection.id |appTranslationKeys)?.translationContent |translate}}</span>
    </div>
  </div>
</ng-container>
<ng-template #noApps>-</ng-template>
