import { ApplicationIds } from './application';

export interface IMainMenuItem {
  appId: ApplicationIds;
  translationTitle: string;
  link: string;
}

export interface ISubMenuItem {
  translationTitle: string;
  link: string;
  // TODO add later required authorities
}

export interface IAppConfig {
  appId: ApplicationIds;
  translationTitle: string;
  translationContent: string;
  isCurrentApp: boolean;
  order: number;
}

export class AppActionItem {
  config: IAppConfig;
  clickHandler: Function = () => {};

  constructor(config: IAppConfig) {
    this.config = config;
  }

  onClick() {
    this.clickHandler();
  }
}

export const APP_CONFIGS: IAppConfig[] = [
  {
    appId: ApplicationIds.HPLUS_SERVICE,
    translationTitle: 'APPS.SERVICE.TITLE',
    translationContent: 'APPS.SERVICE.DESCRIPTION',
    isCurrentApp: false,
    order: 1
  },
  {
    appId: ApplicationIds.CUSTOMER_PORTAL,
    translationTitle: 'APPS.CUSTOMER_PORTAL.TITLE',
    translationContent: 'APPS.CUSTOMER_PORTAL.DESCRIPTION',
    isCurrentApp: false,
    order: 0
  },
  {
    appId: ApplicationIds.LEVEL_2,
    translationTitle: 'APPS.LEVEL_2.TITLE',
    translationContent: 'APPS.LEVEL_2.DESCRIPTION',
    isCurrentApp: true,
    order: 4
  },
  {
    appId: ApplicationIds.ELECTRICITY_CALCULATION_TOOL,
    translationTitle: 'APPS.ELECTRICITY_CALCULATION_TOOL.TITLE',
    translationContent: 'APPS.ELECTRICITY_CALCULATION_TOOL.DESCRIPTION',
    isCurrentApp: false,
    order: 2
  },
  {
    appId: ApplicationIds.TENDER_TOOL,
    translationTitle: 'APPS.TENDER_TOOL.TITLE',
    translationContent: 'APPS.TENDER_TOOL.DESCRIPTION',
    isCurrentApp: false,
    order: 3
  },
  {
    appId: ApplicationIds.HPLUS_DONGLE,
    translationTitle: 'APPS.HPLUS_DONGLE.TITLE',
    translationContent: 'APPS.HPLUS_DONGLE.DESCRIPTION',
    isCurrentApp: false,
    order: 5
  },
  {
    appId: ApplicationIds.REMOTE_ADMIN,
    translationTitle: 'APPS.REMOTE_ADMIN.TITLE',
    translationContent: 'APPS.REMOTE_ADMIN.DESCRIPTION',
    isCurrentApp: false,
    order: 6
  },
  {
    appId: ApplicationIds.REMOTE_DESKTOP,
    translationTitle: 'APPS.REMOTE_DESKTOP.TITLE',
    translationContent: 'APPS.REMOTE_DESKTOP.DESCRIPTION',
    isCurrentApp: false,
    order: 7
  },
  {
    appId: ApplicationIds.OMS_TOOL,
    translationTitle: 'APPS.OMS_TOOL.TITLE',
    translationContent: 'APPS.OMS_TOOL.DESCRIPTION',
    isCurrentApp: false,
    order: 8
  }
];

export const ADMIN_CONSOLE_SUBMENU_ITEMS: ISubMenuItem[] = [
  {
    translationTitle: 'SUBMENU.ITEMS.USERS',
    link: '/pages/users'
  },
  {
    translationTitle: 'SUBMENU.ITEMS.GROUPS',
    link: '/pages/groups'
  },
  {
    translationTitle: 'SUBMENU.ITEMS.SETTINGS',
    link: '/pages/account-settings'
  }
];
