import { L1L3AuthService } from 'ngx-auth-util-lib';
import { MatomoTracker } from 'ngx-matomo-client';
import { finalize } from 'rxjs/operators';

import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';

import { environment } from '../../environments/local/environment';
import { MatomoEvent } from '../models/matomoevents';
import { CompanyDataMgmtService } from './company-data-mgmt.service';

declare var _paq: any;

@Injectable({
  providedIn: 'root',
})
export class MatomoConfigService {
  private isDimensionsSetup: boolean;

  constructor(
    private authService: L1L3AuthService,
    private companyDataService: CompanyDataMgmtService,
    private matomoTracker: MatomoTracker,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.setupCustomDimensions();
  }

  private setupCustomDimensions() {
    this.authService.isAuthenticatedSubject.subscribe({
      next: async (authenticated) => {
        if (authenticated && !this.isDimensionsSetup) {
          this.companyDataService
            .getCompanyData()
            .pipe(finalize(() => (this.isDimensionsSetup = true)))
            .subscribe({
              next: (companyData) => {
                if (companyData) {
                  let dimensionValue = companyData.companyName;

                  if (companyData.companyCustomerNumber) {
                    dimensionValue += ` (${companyData.companyCustomerNumber})`;
                  }

                  console.log('Set up custom dimensions...');
                  this.matomoTracker.setCustomDimension(1, dimensionValue);

                  const userInfo = this.authService.getUserInfo();
                  this.matomoTracker.setUserId(userInfo.userId);
                }
              },
              error: (err) => {
                console.error(err);
                this.matomoTracker.setCustomDimension(1, 'unbekannt');
                this.matomoTracker.resetUserId();
              },
            });
        }
      },
    });
  }

  public pushMatomoEvent(eventDTO: MatomoEvent) {
    if (this.isDimensionsSetup) {
      const eventArr = [
        'trackEvent',
        eventDTO.category,
        eventDTO.action,
        eventDTO.name,
      ];

      if (eventDTO.value != null) {
        eventArr.push(eventDTO.value);
      }

      _paq.push(eventArr);
    }
  }
}
